import React from 'react';

import Layout from './layout';

import AboveTheFoldSection from './above-the-fold/section';
import AboveTheFoldBody from './above-the-fold/body';

import RegistrationFormSection from './registration-form/section';
import RegistrationFormBody from './registration-form/body';
import RegistrationErrorBoundary from './registration-form/error-boundary';

import FooterSection from './footer/footer-section';
import FooterBody from './footer/footer-body';

const RegistrationPage = ({ pageContext }) => {
  return (
    <Layout>
      <AboveTheFoldSection>
        <AboveTheFoldBody {...pageContext} />
      </AboveTheFoldSection>

      <RegistrationFormSection>
        <RegistrationErrorBoundary>
          <RegistrationFormBody {...pageContext} />
        </RegistrationErrorBoundary>
      </RegistrationFormSection>

      <FooterSection>
        <FooterBody {...pageContext} />
      </FooterSection>
    </Layout>
  );
};

export default RegistrationPage;
