import React, { useEffect } from 'react';

import { useForm } from 'react-hook-form';

import Joi from 'joi';
import { joiResolver } from '@hookform/resolvers/joi';

import useRegistrationApi from './use-registration-api';
import useRegistrationSuccess from './use-registration-success';

import { useTrackingCodes } from 'kalanso-plugin-hooks';

import GoogleTOS from './google-tos';
import Help from 'gatsby-theme-kalanso-ui/src/components/text/Help';

import TextField from 'gatsby-theme-kalanso-ui/src/components/forms/inputs/TextField';
import Honeypot from 'gatsby-theme-kalanso-ui/src/components/forms/inputs/Honeypot';
import Submit from 'gatsby-theme-kalanso-ui/src/components/forms/inputs/Submit';

import _themeFormStyles from 'gatsby-theme-kalanso-ui/src/components/forms/forms.module.scss';
import _utils from 'gatsby-theme-kalanso-ui/src/styles/utils.module.scss';
import _textStyles from 'gatsby-theme-kalanso-ui/src/components/text/text.module.scss';

const schema = Joi.object({
  fname: Joi.string().required().max(100),
  email: Joi.string()
    .required()
    .email({ minDomainSegments: 2, tlds: { allow: false } })
    .max(255),
  honey: Joi.string().min(0).max(0).allow('').required(),
});

const RegistrationForm = ({ webinar }) => {

  const formMethods = useForm({
    resolver: joiResolver(schema),
    mode: 'onBlur',
  });

  const formValues = formMethods.watch();

  const metadata = useTrackingCodes();

  const [registrationApi, submitRegistration] = useRegistrationApi(
    {
      ...formValues,
      metadata
    },
    webinar,
  );

  const {
    pending: registrationApiPending,
    success: registrationApiSuccess,
    errors: registrationApiErrors,
    payload,
  } = registrationApi;

  const { setError } = formMethods;

  useEffect(() => {
    if (registrationApiErrors) {
      setError('server', { type: 'manual' });
    }
  }, [registrationApiErrors, setError]);

  // Override this in your app!
  useRegistrationSuccess({ success: registrationApiSuccess, payload });

  return (
    <div>
      {formMethods.errors.server && (
        <div
          className={`${_themeFormStyles.fieldset} ${_utils.mt32x} ${_utils.mb32x}`}
        >
          <div className={`${_utils.pr16x} ${_utils.pl16x}`}>
            <div
              className={`${_themeFormStyles.errors} ${_utils.pt16x} ${_utils.pb16x}`}
            >
              {formMethods.errors.server.message ? (
                <p className={`${_themeFormStyles.errorMessage}`}>
                  {formMethods.errors.server.message}
                </p>
              ) : (
                <>
                  <p className={`${_themeFormStyles.errorMessage}`}>
                    Ohh no! Something went wrong. Please refresh your browser
                    and try again...
                  </p>
                  <p className={`${_themeFormStyles.errorMessage}`}>
                    If the problem persists, send an email to info@kalanso.com
                  </p>
                </>
              )}
            </div>
          </div>
        </div>
      )}

      <form
        id="registration-form"
        onSubmit={formMethods.handleSubmit(submitRegistration)}
      >
        <div className={`${_themeFormStyles.fieldset}`}>
          <TextField
            id="fname"
            label="First Name *"
            name="fname"
            fieldCss={`${_utils.ml0x} ${_utils.mr0x}`}
            register={formMethods.register}
            errors={formMethods.errors}
            values={formValues}
          />
        </div>

        <div className={`${_themeFormStyles.fieldset}`}>
          <TextField
            id="email"
            label="Email Address *"
            name="email"
            fieldCss={`${_utils.ml0x} ${_utils.mr0x}`}
            register={formMethods.register}
            errors={formMethods.errors}
            values={formValues}
          />
        </div>

        <div className={`${_themeFormStyles.fieldset}`}>
          <Honeypot register={formMethods.register} />
        </div>

        <div className={`${_themeFormStyles.fieldset}`}>
          <Submit
            disabled={registrationApiPending}
            pending={registrationApiPending}
            pendingText="Saving Your Seat!"
            pendingSubtext="Don't leave the page yet"
            pendingIcon="fa-spinner-third fa-spin"
            idleText="YES! Save My Seat"
            idleIcon="fa-arrow-right"
            idleSubtext="I Want To Learn The Secrets..."
          />

          <Help className={`${_utils.mt16x} ${_textStyles.grayColor}`}>
            We'll send you updates about the webinar and a link you can use to
            join LIVE. Your Information Is Secure. We won't spam you. You're
            free to cancel at any time.
          </Help>
        </div>

        <div className={`${_themeFormStyles.fieldset}`}>
          <GoogleTOS />
        </div>
      </form>
    </div>
  );
};

export default RegistrationForm;
