import React from 'react';
import PropTypes from 'prop-types';

const ScrollIntoView = ({ selector, fallback, children }) => {
  const handleClick = () => {
    let el = document.querySelector(selector);

    if (!el) {
      el = document.querySelector(fallback);
    }

    if (el && typeof el.scrollIntoView === 'function') {
      // el.scrollIntoView({ behavior: 'smooth' });
      el.scrollIntoView();
    }
  };

  return React.Children.map(children, (child) =>
    React.cloneElement(child, { onClick: handleClick }),
  );
};

ScrollIntoView.propTypes = {
  selector: PropTypes.string.isRequired,
  fallback: PropTypes.string,
};

ScrollIntoView.defaultProps = {
  fallback: '#fallback',
};

export default ScrollIntoView;
