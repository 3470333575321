import React from 'react';

import _cardStyles from './card.module.scss';

const CardBody = ({ children }) => {
  return (
    <div className={`${_cardStyles.body}`}>
      {children}
    </div>
  );
};

export default CardBody;