import React from 'react';

import Row from 'gatsby-theme-kalanso-ui/src/components/layout/Row';
import Col from 'gatsby-theme-kalanso-ui/src/components/layout/Col';

import Footer from 'gatsby-theme-kalanso-ui/src/components/footer/Footer';

const FooterBody = () => {
  return (
    <Row justify="Center">
      <Col align="Center">
        <Footer />
      </Col>
    </Row>
  );
};

export default FooterBody;
