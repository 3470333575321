import React from 'react';

import Container from 'gatsby-theme-kalanso-ui/src/components/layout/Container';

import ArtDirectedBackground from 'src/components/art-direction/CollageOne';

const Section = ({ children }) => {
  return (
    <Container
      Background={ArtDirectedBackground}
    >
      {children}
    </Container>
  );
};

export default Section;
