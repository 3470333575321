import React from 'react';
import _styles from './google-tos.module.scss';
import _utils from 'gatsby-theme-kalanso-ui/src/styles/utils.module.scss'

const GoogleTOS = () => {
  return (
    <small className={`${_styles.google} ${_utils.mt32x}`}>
      This site is protected by reCAPTCHA and the Google{' '}
      <a
        className={`${_styles.googleLink}`}
        href="https://policies.google.com/privacy"
      >
        Privacy Policy
      </a>{' '}
      and{' '}
      <a
        className={`${_styles.googleLink}`}
        href="https://policies.google.com/terms"
      >
        Terms of Service
      </a>{' '}
      apply.
    </small>
  );
};

export default GoogleTOS;
