import React from 'react';

import Container from 'gatsby-theme-kalanso-ui/src/components/layout/Container';

import _sectionStyles from './section.module.scss';

const Section = ({ children }) => {
  return (
    <Container
      fullScreen={true}
      className={`${_sectionStyles.withStack} ${_sectionStyles.container}`}
    >
      {children}
    </Container>
  );
};

export default Section;
