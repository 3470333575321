import React from 'react';

import Text from 'gatsby-theme-kalanso-ui/src/components/text/SubHeadline';
import Highlighted from 'gatsby-theme-kalanso-ui/src/components/text/Highlighted';

import _preheadStyles from './pre-headline.module.scss';

const Preheadline = () => {
  return (
    <Text className={`${_preheadStyles.preheadline}`}>
      <Highlighted>Unique Online Training Reveals</Highlighted>
    </Text>
  );
};

export default Preheadline;
