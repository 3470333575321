import { useEffect, useContext } from 'react';
import { navigate } from 'gatsby';

import { AppStateContext } from 'src/context/AppStateProvider';

const useRegistrationSuccess = ({ success }) => {
  const [, dispatch] = useContext(AppStateContext);

  useEffect(() => {
    if (success) {
      dispatch({ type: 'COMPLETE_WEBINAR_SIGNUP' });
      dispatch({ type: 'DISMISS_POPUP' });
      navigate('/success/youre-registered');
    }
  }, [success, dispatch]);
};

export default useRegistrationSuccess;
