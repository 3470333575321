import React from 'react';

import Announcement from 'gatsby-theme-kalanso-ui/src/components/text/Announcement'

import _bannerStyles from './banner.module.scss';

const Banner = () => {
  return (
    <Announcement>
      <p className={`${_bannerStyles.text}`}>
        ATTENTION: OUTDOOR GARDENERS...
      </p>
    </Announcement>
  );
};

export default Banner;
