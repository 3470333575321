import React from 'react';

import Text from 'gatsby-theme-kalanso-ui/src/components/text/Headline';
// import Underlined from 'gatsby-theme-kalanso-ui/src/components/text/Underlined';

import _headlineStyles from './headline.module.scss';

const Headline = () => {
  return (
    <Text className={`${_headlineStyles.headline} ${_headlineStyles.color}`}>
      {'"'}
      How To Make Your Soil Better ...Without The Overwhelm
      {/* What Successful Growers Have{' '}
      <Underlined>Done, Created, and Leveraged</Underlined> For Thousands Of
      Years To Cause Their Gardens To Thrive */}
      {'"'}
    </Text>
  );
};

export default Headline;
