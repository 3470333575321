import React from 'react';

import _iconStyles from './sub-headline-icon.module.scss';

const SubheadlineIcon = () => {
  return (
    <i className={`far fa-calendar ${_iconStyles.icon}`}></i>
  );
};

export default SubheadlineIcon;
