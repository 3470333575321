import React from 'react';

import Row from 'gatsby-theme-kalanso-ui/src/components/layout/Row';
import Col from 'gatsby-theme-kalanso-ui/src/components/layout/Col';

import Headline from './headline';
import Subheadline from './sub-headline';
import Form from './form';

import Card from '../shared/card';
import CardBody from '../shared/card-body'

const Body = (props) => {
  return (
    <>
      <br />
      <br />

      <Row justify="Center">
        <Col align="Center">
          <Card>
            <CardBody>
              <Headline {...props} />
              <Subheadline {...props} />
              <Form {...props} />
            </CardBody>
          </Card>
        </Col>
      </Row>

      <br />
      <br />
    </>
  );
};

export default Body;
