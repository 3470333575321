import React from 'react';

import Text from 'gatsby-theme-kalanso-ui/src/components/text/SubHeadline';
import Icon from './sub-headline-icon';

import Time from '../shared/time';

import _subheadlineStyles from './sub-headline.module.scss';
import _datetimeStyles from './datetime.module.scss';

const Subheadline = (props) => {
  return (
    <Text className={`${_subheadlineStyles.subheadline}`}>
      <div className={`${_datetimeStyles.datetime}`}>
        <Icon />
        <Time {...props} />
      </div>
    </Text>
  );
};

export default Subheadline;
