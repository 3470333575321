import React from 'react';
import PropTypes from 'prop-types';

import ScrollIntoView from 'gatsby-theme-kalanso-ui/src/components/forms/buttons/ScrollIntoView';

import _buttonStyles from 'gatsby-theme-kalanso-ui/src/components/forms/buttons/buttons.module.scss';

import buttonCta from './button-cta';

const Button = ({ selector, buttonId, className }) => {
  return (
    <ScrollIntoView selector={selector}>
      <button
        id={buttonId}
        type="button"
        className={`${_buttonStyles.button} ${_buttonStyles.primary} ${_buttonStyles.largeV2} ${className}`}
      >
        {buttonCta}
      </button>
    </ScrollIntoView>
  );
};

Button.propTypes = {
  selector: PropTypes.string,
  buttonId: PropTypes.string.isRequired,
};

Button.defaultProps = {
  selector: '#registration-form',
};

export default Button;
