import React from 'react';

import Row from 'gatsby-theme-kalanso-ui/src/components/layout/Row';
import Col from 'gatsby-theme-kalanso-ui/src/components/layout/Col';

import Banner from './banner';
// import Header from './header';
import Preheadline from './pre-headline';
import Headline from './headline';
import Subheadline from './sub-headline';
// import ValueStack from './value-stack';
import ButtonContainer from '../shared/button-container';
import Button from '../shared/button';

import _utils from 'gatsby-theme-kalanso-ui/src/styles/utils.module.scss';
import _buttonStyles from 'gatsby-theme-kalanso-ui/src/components/forms/buttons/buttons.module.scss';

const Body = (props) => {
  return (
    <>
      <Row justify="Center" noPadding={true} bgColor={'white'}>
        <Col grow="1">
          <Banner />
        </Col>
      </Row>

      {/* <Row justify="Center">
        <Col grow="1">
          <Header />
        </Col>
      </Row> */}

      <Row grow="1">
        <Col align="Center" justify="Center" className={`${_utils.fullWidth}`}>
          <Preheadline {...props} />
          <Headline {...props} />
          <Subheadline {...props} />

          <ButtonContainer>
            <Button
              {...props}
              className={`${_buttonStyles.largeV2}`}
              buttonId="header--cta"
            />
          </ButtonContainer>
        </Col>
      </Row>

      {/* <Row justify="Center" noPadding={true} bgColor={'white'}>
        <Col grow="1">
          <ValueStack />
        </Col>
      </Row> */}
    </>
  );
};

export default Body;
