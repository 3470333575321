import React from 'react';

import _cardStyles from './card.module.scss';

const Card = ({ children, width = '500' }) => {
  return (
    <div className={`${_cardStyles.container} ${_cardStyles[`mw${width}`]}`}>
      {children}
    </div>
  );
};

export default Card;