import React from 'react';

import formatClassNames from '../../utils/formatClassNames'

import _defaultStyles from './text.module.scss';

const Announcement = ({ children, ...props }) => {
  const { className = '', ...rest } = props;

  const classes = formatClassNames([_defaultStyles.announcement, className])

  return (
    <aside className={`${classes}`} {...rest}>
      {children}
    </aside>
  );
};

export default Announcement;
