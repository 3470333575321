import React from 'react';

import Time from '../shared/time';

import _subheadlineStyles from './sub-headline.module.scss';

const Subheadline = ({ webinar }) => {
  return (
    <h3 className={`${_subheadlineStyles.subheadline}`}>
      <em>
        The FREE Online Training "{webinar.topic}" goes LIVE on <Time webinar={webinar} />
      </em>
    </h3>
  );
};

export default Subheadline;
