import { useEffect, useState, useCallback } from 'react';
import { useFetch } from 'kalanso-plugin-hooks';
import { useRecaptchaV3 } from 'gatsby-plugin-recaptcha-v3';

import registrationUrl from './registration-api-url';

const useRegistrationApi = (formData, webinar) => {
  // Control the form submission status
  const [inFlight, setInFlight] = useState(false);

  const { webinar_id: webinarId } = webinar;

  const [recaptcha, getToken, resetToken] = useRecaptchaV3({
    siteKey: `${process.env.GATSBY_RECAPTCHA_V3_SITE_KEY}`,
    action: 'webinarRegistration',
  });

  const { token } = recaptcha;

  const [api, submitForm, reset] = useFetch(registrationUrl, {
    method: 'POST',
    body: JSON.stringify({
      ...formData,
      token,
      webinarId,
    }),
  });

  const { errors } = api;

  useEffect(() => {
    if (inFlight) {
      if (errors) {
        setInFlight(false);
        resetToken();
      }
    }
  }, [inFlight, errors, resetToken]);

  // Actually submit the form when we have the token
  useEffect(() => {
    if (token) {
      submitForm();
    }
  }, [token, submitForm]);

  const submitRegistration = useCallback(() => {
    reset();
    getToken();

    setInFlight(true);
  }, [getToken, reset]);

  return [
    {
      pending: inFlight,
      success: api.success,
      errors: api.errors,
      payload: api.payload,
    },
    submitRegistration,
  ];
};

export default useRegistrationApi;
