import React from 'react';
import PropTypes from 'prop-types';

import dayjs from 'dayjs';

import _timeStyles from './time.module.scss';

const Time = (props) => {
  const { timeFormat, webinar, ...rest } = props;

  const { start_time: time } = webinar;

  if (!Boolean(time)) {
    return 'TBD';
  }

  const formattedTime = dayjs(time).format(timeFormat);

  return (
    <time
      className={`${_timeStyles.color}`}
      dateTime={time}
      {...rest}
    >
      {formattedTime} EST
    </time>
  );
};

Time.propTypes = {
  timeFormat: PropTypes.string,
  webinar: PropTypes.shape({
    start_time: PropTypes.string.isRequired,
  }).isRequired
};

Time.defaultProps = {
  timeFormat: 'MMMM D @ h:mmA',
};

export default Time;
