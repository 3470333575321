import { useState, useCallback } from 'react';

const useRecaptchaV3 = ({ siteKey, action } = {}) => {

  if (!siteKey) {
    console.warn(`No site key. Recaptcha not enabled.`);
  }

  if (!action) {
    console.warn(`No action defined. Recaptcha not enabled.`);
  }

  const [token, setToken] = useState('');
  const [pending, setPending] = useState(false);

  const getToken = useCallback(() => {
    if ('grecaptcha' in window) {
      setPending(true);

      window.grecaptcha.ready(() => {
        window.grecaptcha
          .execute(`${siteKey}`, {
            action,
          })
          .then((token) => {
            setToken(token);
            setPending(false);
          });
      });
    } else {
      console.warn(`grecaptcha not in window`);
    }
  }, [setToken, siteKey, action]);

  const resetToken = useCallback(() => {
    setToken('')
  }, [setToken])

  return [{ pending, token }, getToken, resetToken];
};

export default useRecaptchaV3;
