import React from 'react';

import _buttonStyles from 'gatsby-theme-kalanso-ui/src/components/forms/buttons/buttons.module.scss';
import _utils from 'gatsby-theme-kalanso-ui/src/styles/utils.module.scss';

import _fallbackStyles from './fallback-error-view.module.scss';

const FallbackErrorView = () => {
  return (
    <section id="registration-fallback" className={_fallbackStyles.container}>
      <div className={`${_fallbackStyles.inner}`}>
        <div className={`${_fallbackStyles.body}`}>
          <h1 className={`${_fallbackStyles.title}`}>
            Something went wrong with your registration.
          </h1>

          <ul className={`${_utils.flexColumn} ${_utils.list} ${_fallbackStyles.list}`}>
            <li
              className={`${_utils.flexColumn} ${_utils.flexRowTabletAndUp} ${_fallbackStyles.item}`}
            >
              <div
                className={`${_utils.flexColumn} ${_utils.flexAlignCenter} ${_utils.flexJustifyCenter} ${_fallbackStyles.bullet}`}
              >
                <i className={`far fa-fire-extinguisher`}></i>
              </div>
              <div className={`${_utils.flexColumn}`}>
                <p className={`${_fallbackStyles.label}`}>
                  1. We've been automatically notified about the issue and will
                  look into it.
                </p>
              </div>
            </li>

            <li
              className={`${_utils.flexColumn} ${_utils.flexRowTabletAndUp} ${_fallbackStyles.item}`}
            >
              <div
                className={`${_utils.flexColumn} ${_utils.flexAlignCenter} ${_utils.flexJustifyCenter} ${_fallbackStyles.bullet}`}
              >
                <i className={`far fa-sync`}></i>
              </div>
              <div className={`${_utils.flexColumn}`}>
                <p className={`${_fallbackStyles.label}`}>
                  2. Try refreshing your browser.
                </p>
              </div>
            </li>

            <li
              className={`${_utils.flexColumn} ${_utils.flexRowTabletAndUp} ${_fallbackStyles.item}`}
            >
              <div
                className={`${_utils.flexColumn} ${_utils.flexAlignCenter} ${_utils.flexJustifyCenter} ${_fallbackStyles.bullet}`}
              >
                <i className={`far fa-paper-plane`}></i>
              </div>
              <div className={`${_utils.flexColumn}`}>
                <p className={`${_fallbackStyles.label}`}>
                  3. Or chat with us to register for the webinar.
                </p>
              </div>
            </li>
          </ul>

          <div
            className={`
              ${_buttonStyles.center} 
              ${_buttonStyles.fullWidth} 
              ${_buttonStyles.autoWidthTabletAndUp}
              ${_buttonStyles.large}
            `}
          >
            <a
              id="fallback-contact"
              href="https://kalanso.com/pages/contact-us"
              className={`
                ${_buttonStyles.button} 
                ${_buttonStyles.primary} 
                ${_buttonStyles.asButton}
              `}
            >
              Contact Us
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

FallbackErrorView.propTypes = {};

FallbackErrorView.defaultProps = {};

export default FallbackErrorView;
