import React from 'react';
import FallbackErrorView from './fallback-error-view';

import { Notifier } from '@airbrake/browser';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };

    if (process.env.GATSBY_ACTIVE_ENV === 'production') {
      this.airbrake = new Notifier({
        projectId: process.env.GATSBY_AIRBRAKE_PROJECT_ID,
        projectKey: process.env.GATSBY_AIRBRAKE_PROJECT_KEY,
      });
    }
  }

  componentDidCatch(error, errorInfo) {
    // Fallback UI
    this.setState({ hasError: true });

    if (this.airbrake) {
      // Send error to airbrake
      this.airbrake.notify({
        error: error,
        params: { info: errorInfo },
      });
    }
  }

  render() {
    if (this.state.hasError) {
      return <FallbackErrorView />;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
